import React, { useState, useEffect, useRef, useContext } from "react"
import { useCookies } from 'react-cookie';
import { navigate } from "gatsby"
import { useForm } from "react-hook-form"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"
import isEqual from "lodash.isequal"

/* Import Global Context(s) */
import TokenContext from "~context/token"
import AccountContext from "~context/account"

import axios from "axios"

/* Import Global Hook(s) */
import usePreviousValue from "~hooks/usePreviousValue"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

import "./account-login-form.scss"

const AccountLoginForm = ({ saved, emailQuery }) => {

  const [feedback, setFeedback] = useState()

  const [cookies, setCookie] = useCookies(['sessionToken']);

  const [tokenString, setToken] = useContext(TokenContext);
  const [accountInfo, setAccountInfo] = useContext(AccountContext);

	const formRef = useRef()
	const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = useForm({ mode: "onChange" })


  useEffect(() => {

    if(accountInfo !== false && tokenString !== false){
      
      if(saved){
        navigate("/vip/profile?saved=1")
      }else{
        navigate("/vip/profile")
      }
      
    }

    if(emailQuery){
      // populate email address field
      setValue("email", emailQuery, { shouldValidate: false })
    }

  }, [accountInfo, tokenString, saved, setValue, emailQuery])


  const onSubmit = async (data) => {
    console.log('account login attempt...')
    // console.log(data)

    setFeedback('Logging in...')

    axios
    .post("/.netlify/functions/vipUsers", {
      contents: data,
      path: '/vips/auth/login'
    })
    .then(res => {
      console.log('successful request: ')
      // console.log(res)
      // console.log(res.data)

      if(res.data.status === 422){
        console.log(res.data.data.errors)
        setFeedback(res.data.data.message)
        setTimeout(() => {
          setFeedback(false)
        }, 5000)
        return
      }

      setToken(res.data.data.token)
      setCookie('sessionToken', res.data.data.token, { path: '/' })

      setFeedback('Login Successful. Redirecting...')




      axios
      .post('/.netlify/functions/vipUsers', {
        contents: {token: res.data.data.token},
        method: 'with_auth',
        path: '/vips/auth/me'
      })
      .then(result => {

        // console.log(result)
        if(result.data.data !== undefined && result.data.data !== null){
          setAccountInfo(result.data.data)

          if (typeof window !== `undefined`) {
            navigate("/vip/profile")
          }

        }else{
          console.log('unauthorized')
        }
        

      })

    })
    .catch(e => {
      console.error(e)

      setFeedback('There was an error with your request.')
    })
    

  };


	return (
    <form onSubmit={handleSubmit(onSubmit)} className="account-login" ref={formRef}>

    	<input
    		type="email"
        placeholder="Email Address"
        {...register("email", { required: true })}
    	/>

    	<input
        type="password"
        placeholder="Password"
        {...register("password", { required: true })}
      />

      <input
      	type="submit"
      	placeholder="Submit"
      	value="Submit"
      	title="Submit"
      />

      {feedback && <p style={{margin: '2rem auto'}} className="center mt--2 mb--2 font-size--small">{feedback}</p>}

    </form>
  )

}


export default AccountLoginForm