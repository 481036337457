import React, { useEffect, useState, useRef, useContext } from "react"
import { useCookies } from 'react-cookie';
import { navigate } from "gatsby"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Hook(s) */
// import useFirebaseUser from "~hooks/useFirebaseUser"

/* Import Page Component(s) */
import ProfilePage from "~components/pages/profile/profilePage"
// import VipPage from "~components/pages/vip/vipPage/vipPage"
// import RegisterForm from "~components/pages/vip/registerForm/registerForm"
// import LoginForm from "~components/pages/vip/loginForm/loginForm"

import AccountLoginForm from '~components/pages/profile/accountLoginForm'


/* Import Local Style(s) */
import "./vip.scss"

const VIP = ({ location }) => {

  const [cookies, setCookie] = useCookies(['sessionToken']);
  const [email, setEmail] = useState()
  const emailInputRef = useRef()
  
  const queryParams = new URLSearchParams(location.search);
  const isJustSaved = queryParams.get('saved')
  const emailAddressPassed = queryParams.get('email')

  useEffect(() => {
    if (emailInputRef && emailInputRef.current) {
      // get value from input
      setEmail("email")
    }
  }, [emailInputRef])

  if(cookies && cookies.sessionToken){
    if (typeof window !== `undefined`) {
      navigate("/vip/profile")
    }
  }


  // this needs to redirect if token is available:

  // if (firebaseUser && location.pathname !== `/vip/account`) {
  //   if (typeof window !== `undefined`) {
  //     navigate("/vip/account")
  //     return null
  //   }
  // }

  return (
    <ProfilePage title="VIP" header={"VIP"} location={{}}>
      {/*<RegisterForm setEmail={setEmail} />*/}
      
      <AccountLoginForm saved={isJustSaved} emailQuery={emailAddressPassed} />

      {/*<div className="forthcoming">
        <p className="grey">Login forthcoming. In the meantime you may apply for VIP status below.</p>
      </div> */}

      <div className="links">
        <Link to="/vip/reset">Forgot password?</Link>
        <Link to="/vip/apply">Apply for VIP status</Link>
      </div>
    </ProfilePage>
  )
}

export default VIP
